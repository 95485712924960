.clg-logo {
  width: 112px;
  height: 28px;
  background-image: url(https://cloudcache.tencentcs.com/open_proj/proj_qcloud_v2/gateway/login-regist/login/pc/css/sprite/login-202107191023.svg);
  background-position: 1.47% 28.18%;
  background-size: 160.71428571428572% 492.8571428571429%;
  background-repeat: no-repeat;
}

.tcplayerlite-logo {
  height: 36px;
}
.tcplayerlite .tea-layout__content {
  background-color: #fff;
}

.webrtc-container {
  width: 100%;
  max-width: 1024px;
  margin: 10px auto;
}

.webrtc-container .webrtc-input {
  width: 100%;
}

.webrtc-container .tea-form .tea-form__controls {
  padding-right: 0;
}

.tcplayerlite .clarity-switcher {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.tcplayerlite .preview-btn {
  display: flex;
  justify-content: space-between;
}

.tcplayerlite .preview-btn button {
  width: 49%;
}

.tcplayerlite .btn-mobile-preview { 
  width: 100%!important;
}

.qr-tips {
  display: block;
  padding-left: 20px;
  position: relative;
  top: -10px;
}

.tcplayerlite .tcplayer-container {
  width: 100%;
  height: 42rem;
  margin-top: 20px;
}

@media (min-width: 414px) and (max-width: 768px) {
  .tcplayerlite .tcplayer-container {
    height: 28rem;
  }
}

@media (max-width: 414px) { 
  .tcplayerlite .tcplayer-container {
    height: 16rem;
  }
}

.tcplayerlite .manipulate-btns {
  margin-top: 20px;
  text-align: left;
}

.tcplayerlite .manipulate-btns button {
  margin: 10px 10px 0 0;
  padding: 0 16px;
}

.webrtc-support {
  width: 100%;
  margin: 10px 0;
  text-align: right;
}

.webrtc-support .tea-badge{
  border-radius: 2px;
  margin-left: 10px;
}

.tea-bubble {
  max-width: 500px;
}

.tcplayer-wrapper {
  position: relative;
}

.statistics-panel {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 10px;
}

.stat-wrapper {
  color: #fff;
}
.stat-wrapper li {
  height: 12px;
  margin-left: 10px;
  display: flex;
  justify-content: space-between;
}


.top-area {
  position: relative;
  width: 100%;
  height: 260px;
  text-align: center;
  background: url(https://tcplayer-1306264703.cos.ap-nanjing.myqcloud.com/picture/banner.png);
  background-size: cover;
}
.top-title {
  margin: 56px 0 24px 0;
  display: block;
  font-size: 42px;
  color: #fff;
}
.sub-title {
  color: #fff;
  font-size: 24px;
  display: block;
}
.top-btns .tea-btn{
  width: 160px;
  height: 40px;
  font-size: 14px;
}

.top-btns .tea-btn a {
  text-decoration: none;
}

.top-btns .primary-btn {
  margin-right: 20px;
}

.top-btns .secondary-btn {
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
}

.top-btns .tea-btn a {
  color: #fff;
}

.more-btn {
  color:#006eff;
  text-decoration: underline;
}

.format-tabs {
  text-align: center;
}

.format-tabs .tea-tabs__tabitem {
  min-width: 120px;
}

.tea-segment .tea-btn {
  border: 0;
  margin-top: 4px;
}

.tea-segment .tea-btn.is-selected {
  border-radius: 4px!important;
  background: #DDECFF;
  color: #1B79F9;
  border: 0;
}

.tea-segment .tea-btn:hover {
  border-radius: 4px!important;
  background: #DDECFF!important;
  color: #1B79F9;
  border: 0;
  border-style: none;
}

.card {
  border-radius: 4px;
  border: 1px solid #FFFFFF;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.06);
}

.card-notice {
  height: 42px;
  width: 1360px;
  display: flex;
  align-items: center;
  justify-content: start;
  position: absolute;
  left: 50%;
  bottom: -21px;
  transform: translate(-50%, 0);
  background-image: linear-gradient(180deg, #F3F5F8 0%, #FFFFFF 100%);
}

.card-notice img {
  margin: 0 10px 0 20px;
}
.card-notice a {
  margin: 0 5px;
}

.card-player-function {
  padding: 20px;
  margin-top: 20px;
}

.card-preview {
 padding: 14px 0 20px 0;
}

.tea-nav__logo {
  margin-right: 20px;
}

.tea-nav__logo:before {
  display: none;
}

.tab-demo {
  position: relative;
  height: 100%;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding: 0 20px;
}
.underline {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: #fff;
}

.tab-doc {
  height: 100%;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding: 0 20px;
}
.tea-player-wrapper {
  display: flex;
}

#player-container-id {
  width: 100%;
  height: 360px;
}

@media screen and (max-width: 480px) {
  #player-container-id {
    width: 100%;
    height: 240px;
  }
}

.tea-code {
  position: relative;
  width: calc(100% - 640px - 20px);
  background: #fff;
  border: 1px solid #DDDDDD;
}
.tea-code .tea-icon-copy {
  position: absolute;
  right: 0;
  top: 0;
  padding: 20px;
  background-position: center;
  cursor: pointer;
}
.tea-code-pre {
  box-sizing: border-box;
  font-size: 12px;
  height: 360px;
  max-width: 618px;
  overflow: scroll;
}

.iframe-wrapper {
  display: flex;
}
.iframe-wrapper .tea-card {
  width: 640px;
  height: 360px;
  margin: 0 20px 0 0;
}


/* poster */
.vjs-poster {
  width: 100%;
  left: 50%!important;
  top: 50%!important;
  transform: translate(-50%, -50%);
}
.ad-text-node {
  display: inline-block;
  background: rgba(255, 255, 255, 0.2);
  height: 23px;
  margin: 5px;
  padding: 5px;
  font-size: 12px;
}
.ad-close-icon-node {
  width: 14px;
  height: 14px;
  float: right;
  display: inline-block;
  margin-left: 10px;
  background-image: url(https://tcplayer-1306264703.cos.ap-nanjing.myqcloud.com/picture/icon-close.png);
  background-size: cover;
  cursor: pointer;
  margin: 6px;
}


/* sizeadaptive */

#wrap {
  width: 80%;
  margin: 0 auto;
}


/* customui */

.tcplayer-theme-custom-ui .vjs-play-progress{
  background-color: #ff6429;
}
.tcplayer-theme-custom-ui .vjs-play-control.vjs-playing .vjs-icon-placeholder:before {
  color: #ff6429;
}
.tcplayer-theme-custom-ui .vjs-play-control.vjs-ended .vjs-icon-placeholder:before {
  color: #ff6429;
}
.tcplayer-theme-custom-ui .vjs-play-control .vjs-icon-placeholder:before {
  color: #ff6429;
}
.tcplayer-theme-custom-ui .vjs-volume-panel .vjs-volume-level {
  background-color: #ff6429;
}
.tcplayer-theme-custom-ui .vjs-mute-control .vjs-icon-placeholder:before {
  color: #ff6429;
}


#player-container-id1 {
  margin-bottom: 5px;
}